import { extendTheme } from '@chakra-ui/react';

// Or export `extendBaseTheme` if you only want the default Chakra theme tokens to extend (no default component themes)
export const customTheme = extendTheme({
	styles: {
		global: {
			'html, body': {
				fontFamily: 'Zen Old Mincho, sans-serif',
				fontSize: '62.5%',
				backgroundColor: '#000000',
				color: '#ffffff',
				scrollBehavior: 'smooth',
			},
		},
	},
	components: {
		Heading: {
			baseStyle: {
				fontFamily: 'Zen Old Mincho, sans-serif',
			},
		},
		Text: {
			baseStyle: {
				fontFamily: 'Zen Old Mincho, sans-serif',
			},
		},
	},
});
